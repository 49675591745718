import React     from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

class NavbarLink extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const className = 'navigation-navigation__item' + (this.props.className ? ' ' + this.props.className : '');

		return <NavLink {...this.props} className={className} activeClassName="is-active" />;
	}
}

NavbarLink.propTypes = {
	className: PropTypes.string,
};

NavbarLink.defaultProps = {
	className: '',
};

export default NavbarLink;