import React                       from 'react';
import Translations                from '../../../utils/Translations';
import Flux                        from '../../../flux/Flux';
import SurveyFeedbackButton        from '../../Survey/SurveyFeedbackButton';
import NavbarLanguageSelector      from '../Navbar/NavbarLanguageSelector';
import MobileRoutes                from '../Router/MobileRoutes';
import NavbarLink                  from '../Navbar/NavbarLink';
import PropTypes                   from 'prop-types';
import {trackFooterClick}          from '../../../utils/GAHelper';
import {VXPay}                     from '../../../utils/VXPay';
import {generateKey, urlWithQuery} from '../../../utils/CommonUtils';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state               = {
            languageSelectorOpen: false,
            showSEOContent:       false,
            top10SexoleGirls:     [],
        };
        this.lngSelectorTrigger  = this.lngSelectorTrigger.bind(this);
        this.handleSEOTextToggle = this.handleSEOTextToggle.bind(this);
        this.isSexole            = Flux.Browser.isSexole();
    }

    componentDidMount() {
        this.isSexole && this.getTop10SexoleGirls();
    }

    lngSelectorTrigger() {
        trackFooterClick('language');
        this.setState({languageSelectorOpen: !this.state.languageSelectorOpen});
    }

    openVXPaySettings(e) {
        e.preventDefault();
        trackFooterClick('abosettings', '#');
        VXPay.openSettings();
    }

    handleSEOTextToggle() {
        this.setState(prevState => ({
            showSEOContent: !prevState.showSEOContent
        }));
    }

    getTop10SexoleGirls() {
        const args = {
            collectionNames: ['spanish'],
            firstModels:     10,
        };
        Flux.CollectionsV2.getCollections({...args}, (res) => {
            if (res.data.collections_v2.length > 0) {
                const models = res.data.collections_v2[0].models.items;
                this.setState({
                    top10SexoleGirls: models
                });
            }
        });
    }

    render() {
        const language               = Flux.VXMobile.getLanguage();
        const languages              = [Flux.Constants.Languages.DE, Flux.Constants.Languages.EN, Flux.Constants.Languages.ES];
        const today                  = new Date();
        const socialLinks            = Flux.FooterLinks.getSocialLinks();
        const zendeskLink            = Flux.FooterLinks.getZendeskLink(language);
        const VxCashLink             = Flux.FooterLinks.getVxCashLink();
        const becomeAVisitXModelLink = Flux.FooterLinks.getBecomeAVisitXModelLink(language);
        const reportContentLink      = Flux.FooterLinks.getReportContentLink(language);

        const classNames = ['footer-mobile'];

        if (this.props.withMargin) {
            classNames.push(' -with-margin');
        }
        let SEOText = null;

        if (Flux.Browser.isSpanishPfm()) {
            const sexoleTopModelsHTML = this.state.top10SexoleGirls.map((girl, index, array) => (
                    `<span key=${generateKey(girl.name, index)}><a class='footer-mobile-seo-texts__text footer-mobile-seo-texts__text--link' href=${girl.linkVX}>${girl.name}</a>${index === array.length - 1 ? '' : ', '}</span>`
            )).join("");

            SEOText = (
                    <div className={`footer-mobile-seo-texts__container`}>
                        <i className={`icon -icon-single-arrow-down-line footer-mobile-seo-texts__toggle-icon footer-mobile-seo-texts__toggle-icon${this.state.showSEOContent ? '--up ' : ''}`}
                           onClick={this.handleSEOTextToggle}></i>
                        <div className={`footer-mobile-seo-texts__content ${this.state.showSEOContent ? '' : 'footer-mobile-seo-texts__content--hide'}`}>
                            <div className={'footer-mobile-seo-texts__text-block'}>
                                <h4 className="footer-mobile-seo-texts__text footer-mobile-seo-texts__text--header">{this.isSexole ? Translations.get('SexoleHomepageSEO1-header') : Translations.get('SpanishHomepageSEO1-header')}</h4>
                                <p className="footer-mobile-seo-texts__text footer-mobile-seo-texts__text--body"
                                   dangerouslySetInnerHTML={{__html: this.isSexole ? Translations.get('SexoleHomepageSEO1-body', sexoleTopModelsHTML) : Translations.get('SpanishHomepageSEO1-body')}} />

                            </div>
                            <div className={'footer-mobile-seo-texts__text-block'}>
                                <h4 className="footer-mobile-seo-texts__text footer-mobile-seo-texts__text--header">{this.isSexole ? Translations.get('SexoleHomepageSEO2-header') : Translations.get('SpanishHomepageSEO2-header')}</h4>
                                <p className="footer-mobile-seo-texts__text footer-mobile-seo-texts__text--body">{this.isSexole ? Translations.get('SexoleHomepageSEO2-body') : Translations.get('SpanishHomepageSEO2-body')}</p>
                            </div>
                        </div>
                    </div>
            );
        }
        return (
                <div className={classNames.join(' ')}>
                    <ul className="footer-mobile__list">
                        <li className="footer-mobile__list-item">
                            <SurveyFeedbackButton isMobile={true} callback={() => trackFooterClick('feedback')} />
                        </li>
                        <li className="footer-mobile__list-item">
                            <a onClick={this.lngSelectorTrigger}
                               className="footer-mobile__list-item-lng"
                            >
                                {Translations.get('FooterLanguageSelectionLabel')}
                                <span className="footer-mobile__list-item-url__current-lng">
								<span className={'navigation-navigation__icon--language badge-language -lang-' + language} />
								<span>{Translations.get('Language' + language.substr(0, 1).toUpperCase() + language.substr(1, 1))}</span>
								<i className={'icon -icon-single-arrow-down-line footer-mobile__list-item-lng-icon' + (this.state.languageSelectorOpen ? " footer-mobile__list-item-lng-icon--open" : "")} />
							</span>
                            </a>
                            <div className={`footer-mobile__list-item-language-selector ${this.state.languageSelectorOpen ? 'open' : 'close'}`}>
                                <NavbarLanguageSelector languages={languages} />
                            </div>
                        </li>
                        <li className="footer-mobile__list-item">
                            <a target="_blank"
                               className="footer-mobile__list-item-url"
                               href={zendeskLink}
                               rel="nofollow"
                               onClick={() => trackFooterClick('service', zendeskLink)}
                            >
                                {Translations.get('FooterLabelSupport')}
                            </a>
                        </li>
                        <li className="footer-mobile__list-item">
                            <a target="_blank"
                               className="footer-mobile__list-item-url"
                               href={becomeAVisitXModelLink}
                               onClick={() => trackFooterClick('becamgirl', becomeAVisitXModelLink)}
                            >
                                {Translations.get('FooterBecomeAVisitXModel')}
                            </a>
                        </li>
                        <li className="footer-mobile__list-item">
                            <a className="footer-mobile__list-item-url"
                               href={VxCashLink}
                               target="_blank"
                               onClick={() => trackFooterClick('partner', VxCashLink)}
                            >
                                {Translations.get('FooterLabelAffiliate')}
                            </a>
                        </li>
                        <li className="footer-mobile__list-item">
                            <a className="footer-mobile__list-item-url"
                               href="#"
                               target="_blank"
                               onClick={this.openVXPaySettings}
                            >
                                {Translations.get('FooterAboSettings')}
                            </a>
                        </li>
                    </ul>

                    {SEOText}

                    <div className="footer-mobile__line"></div>
                    <div className="footer-mobile__copyright-url">
                        <NavbarLink className="-is-static"
                                    to={MobileRoutes.getLink(MobileRoutes.TERMS_OF_SERVICE)}
                                    onClick={() => trackFooterClick('agb', MobileRoutes.getLink(MobileRoutes.TERMS_OF_SERVICE))}>
                            {Translations.get('FooterLabelTermsOfService')}
                        </NavbarLink>
                        <NavbarLink className="-is-static"
                                    to={MobileRoutes.getLink(MobileRoutes.IMPRINT)}
                                    onClick={() => trackFooterClick('impr', MobileRoutes.getLink(MobileRoutes.IMPRINT))}>
                            {Translations.get('FooterLabelImprint')}
                        </NavbarLink>
                        <NavbarLink className="-is-static"
                                    to={MobileRoutes.getLink(MobileRoutes.DATA_PROTECTION)}
                                    onClick={() => trackFooterClick('ds', MobileRoutes.getLink(MobileRoutes.DATA_PROTECTION))}>
                            {Translations.get('FooterLabelDataProtection')}
                        </NavbarLink>
                    </div>
                    <div className="footer-mobile__copyright-url">
                        <NavbarLink className={"-is-static"}
                                    to={urlWithQuery(MobileRoutes.getLink(MobileRoutes.TERMS_OF_SERVICE), {scrollTo: 'widerruf'})}
                                    onClick={() => trackFooterClick('withdr', urlWithQuery(MobileRoutes.getLink(MobileRoutes.TERMS_OF_SERVICE), {scrollTo: 'widerruf'}))}>
                            {Translations.get('FooterLabelWithdrawal')}
                        </NavbarLink>
                        <a className={"navigation-navigation__item -is-static"}
                                    href={reportContentLink}
                                    target="_blank"
                                    onClick={() => trackFooterClick('report')}>
                            {Translations.get('FooterLabelReportContent')}
                        </a>        
                        {language !== Flux.Constants.Languages.ES && 
                            <NavbarLink className={"-is-static"}
                                    to={urlWithQuery(MobileRoutes.getLink(MobileRoutes.COMPLIANCE_NOTICE_2257), {scrollTo: 'widerruf'})}
                                    onClick={() => trackFooterClick('withdr', urlWithQuery(MobileRoutes.getLink(MobileRoutes.COMPLIANCE_NOTICE_2257), {scrollTo: 'widerruf'}))}>
                                {Translations.get('FooterLabelComplianceNotice2257')}
                            </NavbarLink>
                        }        
                    </div>
                    {language === Flux.Constants.Languages.ES && <div className='footer-mobile__copyright-url'>
                        <NavbarLink className={"-is-static"}
                                    to={urlWithQuery(MobileRoutes.getLink(MobileRoutes.COMPLIANCE_NOTICE_2257), {scrollTo: 'widerruf'})}
                                    onClick={() => trackFooterClick('withdr', urlWithQuery(MobileRoutes.getLink(MobileRoutes.COMPLIANCE_NOTICE_2257), {scrollTo: 'widerruf'}))}>
                            {Translations.get('FooterLabelComplianceNotice2257')}
                        </NavbarLink>
                    </div>}
                    <div className="footer-mobile__navigation-social">
                        {
                            Object.keys(socialLinks).map((link, index) =>
                                    <a key={index}
                                       href={socialLinks[link]}
                                       className="footer-mobile__navigation-social-item"
                                       rel="nofollow me"
                                       target="_blank"
                                       onClick={() => trackFooterClick(link, socialLinks[link])}
                                    >
                                        <i className={`icon -icon-social-${link}`} />
                                    </a>
                            )
                        }
                    </div>
                    <div className={"footer-mobile__copyright"}>
                        <div className="footer-mobile__copyright-text">
                            &copy; {today.getFullYear()} - Made with <span className="icon -icon-heart-full h-color-highlight" /> by VISIT-X in Amsterdam, NL
                        </div>
                    </div>
                </div>
        );
    }
}

Footer.propTypes = {
    withMargin: PropTypes.bool,
};

Footer.defaultProps = {
    withMargin: false,
};

export default Footer;
