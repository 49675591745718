import React        from 'react';
import PropTypes    from 'prop-types';
import Flux         from '../../../flux/Flux';
import Translations from '../../../utils/Translations';
import { generateKey } from '../../../utils/CommonUtils';

class NavbarLanguageSelector extends React.Component {

	constructor (props) {
		super(props);
	}

	render() {
		const selectedLanguage = Flux.VXMobile.getLanguage();

		const languages = this.props.languages.map((language, idx) => (
			<a
				key={generateKey(language, idx)}
				href={this.props.currentPath + '?lang=' + language}
				className={'navigation-navigation__button--language' + (selectedLanguage === language ? ' is-active' : '')}
			>
				<span className={'navigation-navigation__icon--language badge-language -lang-' + language}></span>
				<span className="navigation-navigation__text--language">{Translations.get('Language' + language.substr(0, 1).toUpperCase() + language.substr(1, 1))}</span>
			</a>
		));

        return (
                <div className="navigation-navigation__container--language-selector">
                    {languages}
                </div>
        );
	}
}

NavbarLanguageSelector.propTypes = {
	currentPath: PropTypes.string,
	languages:   PropTypes.array.isRequired,
};

NavbarLanguageSelector.defaultProps = {
	currentPath: '',
};

export default NavbarLanguageSelector;
